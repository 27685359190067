import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { FaMoon, FaStar, FaMosque, FaHandHoldingHeart } from 'react-icons/fa';

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    background-color: #0a2e38;
    color: #f0e6d2;
    font-family: 'Amiri', serif;
  }
`;

// Animations
const twinkle = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 1; }
  100% { opacity: 0.4; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

// Styled components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
`;

const Header = styled.header`
  position: relative;
  padding: 2rem;
  background: linear-gradient(to right, #1a5f7a, #0a2e38);
  border-radius: 10px;
  margin-bottom: 2rem;
`;

const CrescentMoon = styled(FaMoon)`
  color: #f0e6d2;
  font-size: 4rem;
  animation: ${float} 3s ease-in-out infinite;
`;

const Star = styled(FaStar)`
  color: #f0e6d2;
  animation: ${twinkle} 1.5s infinite;
  position: absolute;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin: 1rem 0;
  color: #d4af37;
`;

const Content = styled.div`
  background: rgba(10, 46, 56, 0.8);
  padding: 2rem;
  border-radius: 10px;
  backdrop-filter: blur(5px);
`;

const RamadanButton = styled.button`
  background-color: #1a5f7a;
  color: #f0e6d2;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 1rem;

  &:hover {
    background-color: #d4af37;
    transform: scale(1.05);
  }
`;

// RamadanTheme component
const RamadanTheme = ({ children }) => {
  // Generate random stars
  const stars = Array.from({ length: 20 }).map((_, i) => ({
    id: i,
    size: Math.random() * 3 + 1,
    top: Math.random() * 100,
    left: Math.random() * 100,
    delay: Math.random() * 2,
  }));

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          {stars.map(star => (
            <Star
              key={star.id}
              style={{
                top: `${star.top}%`,
                left: `${star.left}%`,
                fontSize: `${star.size}rem`,
                animationDelay: `${star.delay}s`,
              }}
            />
          ))}
          <CrescentMoon />
          <Title>Ramadan Kareem</Title>
          <FaMosque style={{ fontSize: '2rem', marginBottom: '1rem' }} />
        </Header>
        
        {/* <Content>
          {children}
          
          <div style={{ marginTop: '2rem' }}>
            <FaHandHoldingHeart style={{ marginRight: '10px' }} />
            <span>Charity Reminder: Donate to those in need</span>
          </div>
        </Content> */}
      </Container>
    </>
  );
};

export default RamadanTheme;