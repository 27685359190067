import React from "react";
import "./Banner.css";

const Banner = ({ title, subtitle, buttonText, onButtonClick, infoText }) => {
  return (
    <div className="full-screen-banner">
      <div className="info-bar">
        <div className="info-content">
          <svg className="info-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#131c4c">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-.88-11.75h1.75v1.75h-1.75zm0 3.5h1.75v5.25h-1.75z" />
          </svg>
          <span className="info-text">{title}</span>
        </div>
      </div>
    </div>
  );
};

export default Banner;
