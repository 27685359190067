import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { 
  FaMoon, 
  FaStar, 
  FaMosque, 
  FaHandHoldingHeart, 
  FaUtensils, 
  FaPrayingHands, 
  FaCalendarAlt,
  FaQuran
} from 'react-icons/fa';

// Global styles
const GlobalStyle = createGlobalStyle`


@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

  body {
    
   
  }
`;

// Animations
const twinkle = keyframes`
  0% { opacity: 0.4; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.2); }
  100% { opacity: 0.4; transform: scale(1); }
`;

const float = keyframes`
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-15px) rotate(5deg); }
  100% { transform: translateY(0px) rotate(0deg); }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const lampSwing = keyframes`
  0% { transform: rotate(-5deg); }
  50% { transform: rotate(5deg); }
  100% { transform: rotate(-5deg); }
`;

const lampGlow = keyframes`
  0% { filter: drop-shadow(0 0 5px rgba(214, 168, 46, 0.7)); }
  50% { filter: drop-shadow(0 0 15px rgba(214, 168, 46, 0.9)); }
  100% { filter: drop-shadow(0 0 5px rgba(214, 168, 46, 0.7)); }
`;

// Container with parallax effect
const ParallaxContainer = styled.div`
  perspective: 1000px;
 
  overflow-x: hidden;
  overflow-y: hidden;
  perspective-origin: 50% 50%;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
 display: contents;
  transform-style: preserve-3d;
`;

// Glowing border effect
const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px #4aa5d4, 0 0 20px #4aa5d4, 0 0 30px #4aa5d4; }
  50% { box-shadow: 0 0 15px #d4af37, 0 0 25px #d4af37, 0 0 35px #d4af37; }
  100% { box-shadow: 0 0 10px #4aa5d4, 0 0 20px #4aa5d4, 0 0 30px #4aa5d4; }
`;

const Header = styled.header`
  position: relative;
  padding: 3rem 2rem 7rem;
  background: linear-gradient(135deg, #051937, #062c43, #054569, #17324a);
  border-radius: 0px;
 
  overflow: hidden;
  transform-style: preserve-3d;
  animation: ${fadeIn} 1s ease-out;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #4aa5d4, #d4af37, #4aa5d4);
    background-size: 200% 100%;
    animation: ${shimmer} 3s infinite linear;
    z-index: 1;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #d4af37, #4aa5d4, #d4af37);
    background-size: 200% 100%;
    animation: ${shimmer} 3s infinite linear reverse;
    z-index: 1;
  }
`;

const CrescentMoon = styled(FaMoon)`
  color: #f0e6d2;
  font-size: 5rem;
  animation: ${float} 6s ease-in-out infinite;
  filter: drop-shadow(0 0 15px rgba(240, 230, 210, 0.8));
  transform: translateZ(50px);
  position: absolute;
  top: 30px;
  right: 80px;
`;

const Star = styled(FaStar)`
  color: #f0e6d2;
  animation: ${twinkle} ${props => props.duration || '2s'} infinite;
  position: absolute;
  z-index: 2;
  filter: drop-shadow(0 0 5px rgba(240, 230, 210, 0.5));
  transform: translateZ(${props => props.depth || 30}px);
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin: 1.5rem 0;
  color: #d4af37;
  
font-family: 'Oleo Script', sans-serif;
  text-shadow: 0 0 10px rgba(212, 175, 55, 0.5);
  position: relative;
  display: inline-block;
  transform: translateZ(40px);
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 25%;
    width: 50%;
    height: 3px;
    background: linear-gradient(90deg, transparent, #d4af37, transparent);
  }
`;

const Subtitle = styled.h2`
font-family: 'Oleo Script', sans-serif;
  font-size: 1.5rem;
  margin: 0.5rem 0 2rem;
  color: #4aa5d4;
  font-weight: 300;
  opacity: 0.9;
  transform: translateZ(30px);
`;

// Detailed Mosque SVG
const MosqueContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 2rem;
  transform: translateZ(40px);
`;

// Decorative Lamp Component
const LampContainer = styled.div`
  position: absolute;
  bottom: ${props => props.bottom || '50px'};
  ${props => props.left ? `left: ${props.left};` : ''}
  ${props => props.right ? `right: ${props.right};` : ''}
  transform-origin: top center;
  animation: ${lampSwing} ${props => props.swingDuration || '5s'} ease-in-out infinite;
  z-index: 5;
`;

const Lamp = styled.div`
  position: relative;
  width: 40px;
  height: 80px;
  
  .lamp-chain {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 40px;
    background: #d4af37;
  }
  
  .lamp-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 10px;
    background: #d4af37;
    border-radius: 5px 5px 0 0;
  }
  
  .lamp-body {
    position: absolute;
    top: 10px;
    left: 0;
    width: 40px;
    height: 60px;
    background: linear-gradient(135deg, #d4af37, #e6c76b);
    border-radius: 5px 5px 20px 20px;
    overflow: hidden;
    animation: ${lampGlow} 3s ease-in-out infinite;
  }
  
  .lamp-light {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 60px;
    background: radial-gradient(circle, rgba(255, 215, 0, 0.8) 0%, rgba(255, 215, 0, 0) 70%);
    border-radius: 50%;
    opacity: 0.7;
  }
  
  .lamp-pattern {
    position: absolute;
    top: 15px;
    left: 5px;
    width: 30px;
    height: 40px;
    background: 
      radial-gradient(circle at 50% 25%, transparent 8px, #d4af37 8px, #d4af37 10px, transparent 10px),
      radial-gradient(circle at 50% 50%, transparent 8px, #d4af37 8px, #d4af37 10px, transparent 10px),
      radial-gradient(circle at 50% 75%, transparent 8px, #d4af37 8px, #d4af37 10px, transparent 10px);
  }
`;

// Content with glass morphism
const Content = styled.div`
  background: rgba(6, 44, 67, 0.6);
  padding: 2.5rem;
  border-radius: 16px;
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  animation: ${fadeIn} 1s ease-out 0.3s both;
  transform-style: preserve-3d;
  
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(74, 165, 212, 0.1) 0%, transparent 60%);
    animation: ${rotate} 25s linear infinite;
    pointer-events: none;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
`;

const Card = styled.div`
  background: rgba(5, 25, 55, 0.8);
  border-radius: 12px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  transform: translateZ(20px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  
  &:hover {
    transform: translateZ(30px) scale(1.03);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.3);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: ${props => props.accentColor || '#4aa5d4'};
  }
`;

const CardIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: ${props => props.color || '#d4af37'};
  animation: ${float} 5s ease-in-out infinite;
  animation-delay: ${props => props.delay || '0s'};
`;

const CardTitle = styled.h3`
  font-size: 1.3rem;
  margin: 0.5rem 0;
  color: #f0e6d2;
`;

const CardText = styled.p`
  font-size: 0.95rem;
  color: rgba(240, 230, 210, 0.8);
  line-height: 1.6;
`;

// Modern animated button
const RamadanButton = styled.button`
  position: relative;
  background: linear-gradient(135deg, #1a5f7a, #054569);
  color: #f0e6d2;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 1rem 0.5rem;
  overflow: hidden;
  z-index: 1;
  transform: translateZ(15px);
  
  &:hover {
    transform: translateZ(20px) scale(1.05);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: 0.5s;
    z-index: -1;
  }
  
  &:hover::before {
    left: 100%;
  }
`;

// Calendar badge animation
const calendarPulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const CalendarBadge = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background: #d4af37;
  color: #051937;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  animation: ${calendarPulse} 3s infinite ease-in-out;
  transform: translateZ(25px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

// Prayer times component
const PrayerTimesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 1rem;
`;

const PrayerTime = styled.div`
  background: rgba(26, 95, 122, 0.3);
  padding: 1rem;
  border-radius: 8px;
  flex: 1 1 calc(20% - 1rem);
  min-width: 120px;
  text-align: center;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(26, 95, 122, 0.6);
    transform: translateY(-5px);
  }
  
  @media (max-width: 768px) {
    flex: 1 1 calc(33% - 1rem);
  }
  
  @media (max-width: 480px) {
    flex: 1 1 calc(50% - 1rem);
  }
`;

const PrayerName = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #d4af37;
`;

const PrayerTimeText = styled.div`
  font-size: 0.9rem;
`;

// Interactive progress bar for Ramadan days
const ProgressContainer = styled.div`
  margin: 2.5rem 0;
  position: relative;
`;

const ProgressBar = styled.div`
  height: 10px;
  background: rgba(26, 95, 122, 0.3);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const ProgressFill = styled.div`
  height: 100%;
  width: ${props => props.progress || '0%'};
  background: linear-gradient(90deg, #4aa5d4, #d4af37);
  border-radius: 10px;
  transition: width 1s ease;
`;

const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: rgba(240, 230, 210, 0.8);
`;

// Decorative Lantern Row Component
const LanternRow = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: -60px auto 30px;
  z-index: 10;
  transform: translateZ(35px);
`;

// Modern Ramadan component
const RamdanThemeNew = () => {
  const [ramadanProgress, setRamadanProgress] = useState("35%");
  const [currentDay, setCurrentDay] = useState(10);
  
  // Generate random stars with more variance
  const stars = Array.from({ length: 30 }).map((_, i) => ({
    id: i,
    size: Math.random() * 2.5 + 0.5,
    top: Math.random() * 100,
    left: Math.random() * 100,
    delay: Math.random() * 3,
    duration: `${1.5 + Math.random() * 2}s`,
    depth: Math.floor(Math.random() * 50) + 10,
  }));

  // Simulated prayer times
  const prayerTimes = [
    { name: "Fajr", time: "5:12 AM" },
    { name: "Dhuhr", time: "12:30 PM" },
    { name: "Asr", time: "3:45 PM" },
    { name: "Maghrib", time: "6:32 PM" },
    { name: "Isha", time: "8:00 PM" }
  ];

  // Simulated Ramadan tips
  const ramadanTips = [
    {
      title: "Prayer",
      text: "Strengthen your spiritual connection through consistent prayer and reflection.",
      icon: <FaPrayingHands />,
      color: "#4aa5d4",
      delay: "0.1s",
      accent: "#4aa5d4"
    },
    {
      title: "Charity",
      text: "Extend your generosity to those in need during this blessed month.",
      icon: <FaHandHoldingHeart />,
      color: "#d4af37",
      delay: "0.2s",
      accent: "#d4af37"
    },
    {
      title: "Quran",
      text: "Dedicate time to recite and understand the teachings of the Holy Quran.",
      icon: <FaQuran />,
      color: "#4aa5d4",
      delay: "0.3s",
      accent: "#4aa5d4"
    },
    {
      title: "Iftar",
      text: "Share meals with family and community to strengthen bonds.",
      icon: <FaUtensils />,
      color: "#d4af37",
      delay: "0.4s",
      accent: "#d4af37"
    },
  ];

  return (
    <>
      <GlobalStyle />
      <ParallaxContainer>
        <Container>
          <Header>
            {stars.map(star => (
              <Star
                key={star.id}
                style={{
                  top: `${star.top}%`,
                  left: `${star.left}%`,
                  fontSize: `${star.size}rem`,
                  animationDelay: `${star.delay}s`,
                }}
                duration={star.duration}
                depth={star.depth}
              />
            ))}
            <CrescentMoon />
            <Title>Ramadan Kareem</Title>
            <Subtitle>A month of blessings and spiritual growth</Subtitle>
            
            {/* Decorative hanging lamps in the header */}
            <LampContainer left="15%" bottom="70px" swingDuration="5s">
              <Lamp>
                <div className="lamp-chain"></div>
                <div className="lamp-top"></div>
                <div className="lamp-body">
                  <div className="lamp-pattern"></div>
                </div>
                <div className="lamp-light"></div>
              </Lamp>
            </LampContainer>
            
            <LampContainer left="40%" bottom="40px" swingDuration="6s">
              <Lamp>
                <div className="lamp-chain"></div>
                <div className="lamp-top"></div>
                <div className="lamp-body">
                  <div className="lamp-pattern"></div>
                </div>
                <div className="lamp-light"></div>
              </Lamp>
            </LampContainer>
            
            <LampContainer right="40%" bottom="60px" swingDuration="5.5s">
              <Lamp>
                <div className="lamp-chain"></div>
                <div className="lamp-top"></div>
                <div className="lamp-body">
                  <div className="lamp-pattern"></div>
                </div>
                <div className="lamp-light"></div>
              </Lamp>
            </LampContainer>
            
            <LampContainer right="15%" bottom="30px" swingDuration="7s">
              <Lamp>
                <div className="lamp-chain"></div>
                <div className="lamp-top"></div>
                <div className="lamp-body">
                  <div className="lamp-pattern"></div>
                </div>
                <div className="lamp-light"></div>
              </Lamp>
            </LampContainer>
            
            {/* Detailed Mosque SVG */}
            <MosqueContainer>
              <svg 
                width="100%" 
                height="100%" 
                viewBox="0 0 600 200" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
                style={{ 
                  filter: "drop-shadow(0 0 10px rgba(212, 175, 55, 0.5))",
                  marginTop: "20px"
                }}
              >
                {/* Base of the mosque */}
                <rect x="100" y="120" width="400" height="80" fill="#1a5f7a" />
                
                {/* Main dome */}
                <ellipse cx="300" cy="120" rx="80" ry="50" fill="#1a5f7a" />
                <path d="M220 120 Q300 40 380 120" stroke="#d4af37" strokeWidth="3" fill="none" />
                
                {/* Small domes */}
                <ellipse cx="160" cy="120" rx="40" ry="25" fill="#1a5f7a" />
                <path d="M120 120 Q160 85 200 120" stroke="#d4af37" strokeWidth="2" fill="none" />
                
                <ellipse cx="440" cy="120" rx="40" ry="25" fill="#1a5f7a" />
                <path d="M400 120 Q440 85 480 120" stroke="#d4af37" strokeWidth="2" fill="none" />
                
                {/* Minarets */}
                <rect x="80" y="60" width="20" height="140" fill="#1a5f7a" />
                <polygon points="80,60 90,30 100,60" fill="#1a5f7a" stroke="#d4af37" strokeWidth="2" />
                
                <rect x="500" y="60" width="20" height="140" fill="#1a5f7a" />
                <polygon points="500,60 510,30 520,60" fill="#1a5f7a" stroke="#d4af37" strokeWidth="2" />
                
                {/* Windows and door */}
                <rect x="250" y="140" width="100" height="60" rx="30" fill="#062c43" stroke="#d4af37" strokeWidth="2" />
                
                <rect x="140" y="140" width="30" height="40" rx="15" fill="#062c43" stroke="#d4af37" strokeWidth="1" />
                <rect x="190" y="140" width="30" height="40" rx="15" fill="#062c43" stroke="#d4af37" strokeWidth="1" />
                
                <rect x="380" y="140" width="30" height="40" rx="15" fill="#062c43" stroke="#d4af37" strokeWidth="1" />
                <rect x="430" y="140" width="30" height="40" rx="15" fill="#062c43" stroke="#d4af37" strokeWidth="1" />
                
                {/* Decorative elements */}
                <circle cx="300" cy="90" r="10" fill="#d4af37" />
                <circle cx="160" cy="100" r="5" fill="#d4af37" />
                <circle cx="440" cy="100" r="5" fill="#d4af37" />
                
                <circle cx="90" cy="40" r="3" fill="#d4af37" />
                <circle cx="510" cy="40" r="3" fill="#d4af37" />
                
                {/* Stars on the minarets */}
                <path d="M90,20 L92,26 L98,26 L93,30 L95,36 L90,32 L85,36 L87,30 L82,26 L88,26 Z" fill="#d4af37" />
                <path d="M510,20 L512,26 L518,26 L513,30 L515,36 L510,32 L505,36 L507,30 L502,26 L508,26 Z" fill="#d4af37" />
              </svg>
            </MosqueContainer>
            
          
          </Header>
          
          {/* Row of lanterns connecting header to content */}
          <LanternRow>
            {[...Array(5)].map((_, index) => (
              <LampContainer key={index} bottom={`${index % 2 === 0 ? 0 : 15}px`} swingDuration={`${5 + index * 0.5}s`}>
                <Lamp>
                  <div className="lamp-chain"></div>
                  <div className="lamp-top"></div>
                  <div className="lamp-body">
                    <div className="lamp-pattern"></div>
                  </div>
                  <div className="lamp-light"></div>
                </Lamp>
              </LampContainer>
            ))}
          </LanternRow>
          
         
        </Container>
      </ParallaxContainer>
    </>
  );
};

export default RamdanThemeNew;