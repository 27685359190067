import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import AuthenticationStyle from "../../Styles/AuthenticationStyle";
import { Button, Input, InputAdornment } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AllservicesModify from './ModifyPage.jsx/AllservicesModify';
import FooterModify from './ModifyPage.jsx/FooterModify';
import { getBaseURL } from '../../api/apiManagement';

const ChangePass = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { classes } = props;

  // State variables
  const [data, setData] = React.useState({
    password: "",
    confirmPassword: ""
  });
  const [newPassVisible, setNewPassVisible] = React.useState(false);
  const [confirmPassVisible, setConfirmPassVisible] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);

  const url = getBaseURL() + "/vendor/api/v1/changepassword";

  // Handle input change
  function handle(e) {
    const updatedData = { ...data, [e.target.id]: e.target.value };
    setData(updatedData);

    // Check if passwords match
    if (e.target.id === "password" || e.target.id === "confirmPassword") {
      if (updatedData.password === updatedData.confirmPassword) {
        setPasswordsMatch(true);
        setPasswordError(""); // Clear error if they match
      } else {
        setPasswordsMatch(false);
        setPasswordError("Passwords do not match.");
      }
    }
  }

  // Validate password
  function validatePassword(password) {
    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      setPasswordError("Password must contain at least one uppercase letter.");
      return false;
    }
    if (!/[a-z]/.test(password)) {
      setPasswordError("Password must contain at least one lowercase letter.");
      return false;
    }
    if (!/\d/.test(password)) {
      setPasswordError("Password must contain at least one digit.");
      return false;
    }
    if (!/[@#$%^&*!]/.test(password)) {
      setPasswordError("Password must contain at least one special character.");
      return false;
    }

    setPasswordError("");
    return true;
  }

  // Handle password reset
  function reset() {
    if (!validatePassword(data.password)) {
      return; // Stop if password validation fails
    }

    let params = {
      email: localStorage.getItem("email"),
      new_password: data.password,
      userId: id
    };

    axios.post(url, params).then((res) => {
      if (res.data.status === "success") {
        navigate('/');
      }
    });
  }

  // Toggle password visibility
  function toggleNewPassVisibility() {
    setNewPassVisible(!newPassVisible);
  }

  function toggleConfirmPassVisibility() {
    setConfirmPassVisible(!confirmPassVisible);
  }

  return (
    <>
      <AllservicesModify />
      <Grid container className={classes.coperate_start3}>
        <Grid container className={classes.ht}>
          <Grid item xs={12} md={6} lg={6} className={classes.allm} style={{ display: "flex" }}>
            <img src='../Images/background-1.png' alt="bg" className='background-1' style={{ marginLeft: "0px", objectFit: "contain" }}></img>
          </Grid>
          <Grid item xs={12} md={5} lg={5} className={classes.changedPass} style={{ marginLeft: "50px", marginRight: "20px" }}>
            <div className={classes.coperate_section} style={{ marginRight: "20px" }}>
              <b className={classes.coperate_btn}>Corporate Login</b>
              <h2 className='auth-content-align9'>
                <div className='welcome2'><strong>Change/Reset Password</strong></div>
              </h2>
              <div className='newpass'>
                Password requirements:
                <ul>
                  <li>At least 8 characters long</li>
                  <li>At least one uppercase letter</li>
                  <li>At least one lowercase letter</li>
                  <li>At least one digit</li>
                  <li>At least one special character (@#$%^&*!)</li>
                </ul>
              </div>
              <form>
                <div className='auth-content-align'>
                  <b style={{ display: "flex" }}>Enter new password</b>
                  <Input
                    id="password"
                    type={newPassVisible ? 'text' : 'password'}
                    placeholder="Enter a strong password"
                    onChange={(e) => handle(e)}
                    className={classes.input}
                    startAdornment={
                      <InputAdornment position="start">
                        <img className={classes.msg_icon} src='../Images/email.svg' alt="icon"></img>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" sx={{ paddingRight: "10px", cursor: "pointer" }}>
                        <img onClick={toggleNewPassVisibility} className={classes.msg_icon} src='../Images/eye.png' alt="toggle visibility"></img>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className='auth-content-align'>
                  <b style={{ display: "flex" }}>Confirm new password</b>
                  <Input
                    id="confirmPassword"
                    type={confirmPassVisible ? 'text' : 'password'}
                    placeholder="Re-enter your password"
                    onChange={(e) => handle(e)}
                    className={classes.input}
                    startAdornment={
                      <InputAdornment position="start">
                        <img className={classes.msg_icon} src='../Images/email.svg' alt="icon"></img>
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end" sx={{ paddingRight: "10px", cursor: "pointer" }}>
                        <img onClick={toggleConfirmPassVisibility} className={classes.msg_icon} src='../Images/eye.png' alt="toggle visibility"></img>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="error-message" style={{ color: "red" }}>
                  {passwordError}
                </div>
                <div className='auth-content-align' style={{ display: "flex" }}>
                  <Button 
                    className={classes.login4} 
                    onClick={reset} 
                    disabled={!passwordsMatch || passwordError !== ""}
                  >
                    Change/ Reset Password
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <FooterModify />
    </>
  );
};

export default withStyles(AuthenticationStyle)(ChangePass);
